<template>
  <div>
    <RouterView name="header" />
    <div>
      <RouterView name="navigation" />
    </div>
    <div>
      <RouterView name="default" />
    </div>
  </div>
</template>

<style lang="scss">
  body {
    background-repeat: repeat-y;
    background-size: 100%;
  }
</style>

<script>

export default {
  name: 'App',
  data() {
    return {
      eventsTrigger: [''],
      eventsFired: []
    }
  },
  mounted() {
    window.dataLayer.push({
      event: "pageview",
      content_id: "especiales-noticias-2023-univisionarios",
      content_type: "custom",
      content_created_date: "2023-06-16",
      title: "Univisionarios",
      content_vertical: "noticias",
      section_level1: "noticias",
      section_level2: "especiales",
      section: "Website",
      section_full_hierarchy: "noticias,especiales",
      permalink: "https: //www.univision.com/especiales/noticias/2023/univisionarios/",
      platform: "Website",
      content_author: "Especiales Univision",
      all_tags: "hispanos, univisionarios",
    })
    
    window.addEventListener('scroll', () => {
      this.scrollGA()
    })
  },
  methods: {
    scrollGA() {
      const triggers = document.querySelectorAll('.sections-univ')
      this.activeMenu = false
      Array.from(triggers).forEach((i)=> {
        if(i.dataset.section != this.activateSection) {
          const scrollY = window.scrollY + (window.innerHeight * .7)
          const top = i.offsetTop

          if( top <= scrollY && (top+ i.offsetHeight) >= scrollY) {

            // trigger for scrolldown
            
            if(i.dataset.analytics) {
              if(!this.eventsFired.includes(i.dataset.analytics)) {
                console.log(i.dataset.scroll)
                window.dataLayer.push({
                  event: `section_${i.dataset.scroll}_percent`,
                  widget_pos: 1,
                  section_category: i.dataset.analytics,
                  content_type: "univisionarios",
                })
                this.eventsFired.push(i.dataset.analytics)
              }
            }
          }
        }
      })
    }
  }
};
</script>